.forgot {
  margin-bottom: 0;
  height: 100%;

  & .pds-checkbox-label {
    line-height: 1.3;
  }

  & h2,
  & h3 {
    margin-bottom: var(--spacing-l);
  }
}

.forgot-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .logo {
    margin-bottom: var(--spacing-l);
  }

  & h4 {
    margin-bottom: var(--spacing-l);
  }

  & .forgot-content {
    padding: 0;
  }
}

.forgot-form {
  z-index: 2;
  padding: var(--spacing-xl) 28px;
  width: 380px;
  border: 1px solid var(--color-grey-07);
  border-radius: 4px;
  background: white;
  box-shadow: rgba(0, 0, 0, .1) 0 20px 30px;
}

.forgot-modal-group {
  position: relative;
}

.forgot .modal-button {
  text-transform: uppercase;
}
