@font-face {
  font-family: "Salesforce-Sans";
  src: url("assets/fonts/SalesforceSans-Regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Salesforce-Sans";
  src: url("assets/fonts/SalesforceSans-Bold.woff2") format("woff2");
  font-weight: 600;
}
