@import "./fonts.css";

#root {
  width: 100%;
  height: 100%;
}

body {
  overflow: auto;
  min-width: 1100px;
  min-height: 640px;
}

.app {
  position: relative;
  width: 100%;
  height: 100%;
}

.pds-sider {
  overflow: visible;
}

.pds-layout-content {
  overflow: hidden;
}

.pds-menu-item {
  & .pds-menu-item-inner a {
    outline: none;
    color: var(--color-grey-03);
    text-decoration: none;
  }

  &._active a {
    color: var(--color-primary);
  }
}

.layout-row {
  min-width: 1024px;
  min-height: 600px;
  width: 100%;
  height: 100%;
  background-color: var(--color-grey-07);

  @media only screen and (max-width: 480px) {
    min-width: 0;
  }
}

.label,
.pds-input-label,
.pds-textarea-label {
  display: block;
  margin-bottom: var(--spacing-xs);
  width: 100%;
  color: var(--color-grey-01);
  text-transform: none;
  font-weight: 500;
}

.menu-notification {
  & ~ .pds-popover {
    padding: 0 var(--spacing-s);
  }
}

.menu-profile {
  & ~ .pds-popover {
    margin-right: var(--spacing-s);
    margin-bottom: 2px;
    padding: var(--spacing-s) 0;
    min-width: 180px;
    border-radius: 4px;
    color: var(--color-grey-02);

    & .menu-profile-name {
      padding: 0 var(--spacing-xs);
      font-weight: 500;

      & span {
        font-size: var(--size-text-m);
      }
    }

    & .menu-profile-id {
      padding: 0 var(--spacing-xs);
      color: var(--color-grey-03);

      & span {
        font-size: var(--size-text-s);
      }
    }

    & .pds-button {
      display: flex;
      padding: var(--spacing-xs);
      border: 0;
      color: var(--color-grey-02);
      font-weight: 400;
      opacity: 1;
      justify-content: flex-start;

      & a {
        color: var(--color-grey-02);
        text-decoration: none;

        &:hover a {
          color: var(--color-primary);
        }
      }
    }
  }
}

.modal-help {
  width: 500px;

  & .pds-col {
    width: 100%;
  }
}

.text-bold {
  color: var(--color-grey-01);
  white-space: nowrap;
  font-weight: 600;
}

.pds-button._link._disabled {
  background-color: transparent;
  opacity: .5;
}

.pds-notification._inline {
  & .pds-notice {
    margin: var(--spacing-s) 0 var(--spacing-s);
    width: auto;
  }

  & .pds-notice:first-of-type {
    margin-top: 0;
  }

  & .pds-notice:last-of-type {
    margin-bottom: 0;
  }
}

.menu-secondary.pds-menu._dark ._link {
  color: var(--color-primary);
}

.balance-price-block {
  padding: var(--spacing-s);
  border: 1px solid rgba(0, 0, 0, .04);
  border-radius: 2px;
  background-color: white;
  box-shadow: 0 0 2px rgba(0, 0, 0, .06);
}

.balance-action-block {
  padding: var(--spacing-s);
  width: 100%;
  border-radius: 2px;
  background-color: white;
  color: var(--color-grey-01);
  text-align: center;
}

.ag-cell-focus,
.ag-cell-no-focus {
  outline: none;
  border-color: transparent !important;
}

.ag-cell:focus {
  outline: none;
  border-color: transparent !important;
}

.ag-watermark {
  display: none !important;
  visibility: hidden;
  opacity: 0 !important;
}

.pds-badge-dot._disabled {
  display: none;
}

.layout-row .pds-table.ag-theme-balham .ag-root {
  border-right: none;
  border-bottom: none;
}

#error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#error-container .message {
  text-align: center;
  font-size: 14px;
}

#error-container .header {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

#error-container .icon {
  text-align: center;
}

.action-notification {
  right: 109px !important;
}

.pds-layout .pds-notification {
  @media only screen and (max-width: 480px) {
    right: auto;
    left: 0;
  }

  & .pds-notice {
    margin-left: 0;
  }
}

.pds-modal._medium {
  @media only screen and (max-width: 480px) {
    max-width: 100vw;
  }
}
